// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgElectricVehicleIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path
      fillRule="evenodd"
      d="M11.31 6.996H9.5a.5.5 0 0 1-.45-.28.51.51 0 0 1 .05-.52l3-4.001c.14-.18.38-.24.59-.16.21.09.34.3.31.53l-.3 2.44h1.81a.5.5 0 0 1 .45.28c.08.17.06.37-.05.52l-3 4.002c-.1.13-.25.2-.4.2-.07 0-.13-.02-.19-.04a.505.505 0 0 1-.31-.53zm6.69-2c.31 0 .61.15.8.4.53.71 3.2 4.38 3.2 6.601V21c0 .55-.45 1-1 1h-2c-.55 0-1-.45-1-1v-1H6v1c0 .55-.45 1-1 1H3c-.55 0-1-.45-1-1v-9.003c0-2.22 2.67-5.891 3.2-6.601.19-.25.49-.4.8-.4h1c.55 0 1 .45 1 1s-.45 1-1 1h-.49c-.87 1.23-1.89 2.91-2.31 4.101.86.39 4.32 1.9 7.8 1.9s6.95-1.5 7.8-1.9c-.42-1.2-1.44-2.88-2.31-4.1H17c-.55 0-1-.451-1-1.001s.45-1 1-1zM6.99 16.999c.37 0 .72-.2.9-.55l-.01-.01c.24-.49.04-1.09-.45-1.34l-2-1.001a1.01 1.01 0 0 0-1.34.45c-.24.49-.04 1.09.45 1.34l2 1c.15.08.3.11.45.11m10.46-.11 2-1-.01.01c.5-.25.7-.85.45-1.341-.25-.5-.85-.7-1.34-.45l-2 1c-.5.25-.7.85-.45 1.34a1.007 1.007 0 0 0 1.35.44"
      clipRule="evenodd"
    />
  </Icon>
);
export default SvgElectricVehicleIcon;
SvgElectricVehicleIcon.displayName = 'SvgElectricVehicleIcon';
