import * as eneco from '../../themes/eneco/tokens';
import * as oxxio from '../../themes/oxxio/tokens';
import * as woonenergie from '../../themes/woonenergie/tokens';

/**
 * Button specific tokens
 */

/** Colors - Format: [component][action][property][tone?][state?] */

/**
 * ENECO
 */
export const enecoBadgeColors = {
  badgeSuccessBackground: eneco.secondaryColors.green100,
  badgeSuccessText: eneco.textColors.textOnBackgroundVarFive,

  badgeWarningBackground: eneco.secondaryColors.yellow100,
  badgeWarningText: eneco.textColors.textOnBackgroundVarSix,

  badgeErrorBackground: eneco.secondaryColors.pink100,
  badgeErrorText: eneco.secondaryColors.pink900,

  badgeHighEmphasisBackground: eneco.secondaryColors.purple900,
  badgeHighEmphasisText: eneco.textColors.textInverted,
};

/**
 * OXXIO
 */
export const oxxioBadgeColors = {
  badgeSuccessBackground: oxxio.secondaryColors.green100,
  badgeSuccessText: oxxio.textColors.textOnBackgroundVarFive,

  badgeWarningBackground: oxxio.secondaryColors.yellow100,
  badgeWarningText: oxxio.textColors.textOnBackgroundVarSix,

  badgeErrorBackground: oxxio.secondaryColors.pink100,
  badgeErrorText: oxxio.secondaryColors.pink900,
};

/**
 * WOONENERGIE
 */
export const woonenergieBadgeColors = {
  badgeSuccessBackground: woonenergie.secondaryColors.green100,
  badgeSuccessText: woonenergie.textColors.textOnBackgroundVarFive,

  badgeWarningBackground: woonenergie.secondaryColors.yellow100,
  badgeWarningText: woonenergie.textColors.textOnBackgroundVarSix,

  badgeErrorBackground: woonenergie.secondaryColors.pink100,
  badgeErrorText: woonenergie.secondaryColors.pink900,
};
