import React, { FC, useState, useEffect, useRef } from 'react';

export type SwipeDirection = 'left' | 'right' | 'up' | 'down';

export interface Props {
  children: React.ReactNode;
  onSwipe: (direction: SwipeDirection) => void;
  minSwipeDistance?: number;
}

export const SwipeListener: FC<Props> = ({ children, onSwipe, minSwipeDistance = 100 }) => {
  const [touchstartX, setTouchstartX] = useState<number | null>(null);
  const [touchstartY, setTouchstartY] = useState<number | null>(null);
  const [touchendX, setTouchendX] = useState<number | null>(null);
  const [touchendY, setTouchendY] = useState<number | null>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const calculateSwipeDirection = () => {
      if (touchendX == null || touchstartX == null || touchendY == null || touchstartY == null) return undefined;
      const distanceX = Math.abs(touchendX - touchstartX);
      const distanceY = Math.abs(touchendY - touchstartY);

      if (distanceX < minSwipeDistance && distanceY < minSwipeDistance) {
        return undefined;
      }

      if (distanceX > distanceY) {
        return touchendX < touchstartX ? 'left' : 'right';
      } else {
        return touchendY < touchstartY ? 'up' : 'down';
      }
    };

    const swipeDirection = calculateSwipeDirection();

    swipeDirection && onSwipe(swipeDirection);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [touchendX, touchendY, minSwipeDistance]);

  useEffect(() => {
    const handleTouchStart = (e: TouchEvent) => {
      setTouchstartX(e.changedTouches[0].screenX);
      setTouchstartY(e.changedTouches[0].screenY);
    };

    const handleTouchEnd = (e: TouchEvent) => {
      setTouchendX(e.changedTouches[0].screenX);
      setTouchendY(e.changedTouches[0].screenY);
    };

    const currentRef = wrapperRef.current;
    currentRef?.addEventListener('touchstart', handleTouchStart);
    currentRef?.addEventListener('touchend', handleTouchEnd);

    return () => {
      currentRef?.removeEventListener('touchstart', handleTouchStart);
      currentRef?.removeEventListener('touchend', handleTouchEnd);
    };
  }, []);

  return (
    <div className="sparky-swipe-listener__wrapper" ref={wrapperRef}>
      {children}
    </div>
  );
};
