// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgPlugInIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path d="M12 2C6.49 2 2 6.49 2 12s4.49 10 10 10c.55 0 1-.45 1-1v-4.1a5 5 0 0 0 4-4.9v-1c0-.55-.45-1-1-1h-1V7c0-.55-.45-1-1-1s-1 .45-1 1v3h-2V7c0-.55-.45-1-1-1s-1 .45-1 1v3H8c-.55 0-1 .45-1 1v1a5 5 0 0 0 4 4.9v3.04c-3.94-.49-7-3.87-7-7.94 0-4.41 3.59-8 8-8s8 3.59 8 8c0 3.04-1.7 5.78-4.43 7.15-.49.25-.69.85-.45 1.34.25.49.84.69 1.34.45 3.41-1.71 5.53-5.13 5.53-8.94C22 6.49 17.51 2 12 2" />
  </Icon>
);
export default SvgPlugInIcon;
SvgPlugInIcon.displayName = 'SvgPlugInIcon';
