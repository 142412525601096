import { FC, ReactNode, useEffect } from 'react';

import logger from '@common/log';

import * as sparkyIcons from '../../icons';
import { CloseIcon, type IconProps } from '../../icons';

interface IconLoaderProps extends IconProps {
  iconName?: string;
  fallbackIcon?: ReactNode;
}

export const IconLoader: FC<IconLoaderProps> = ({ iconName = '', fallbackIcon, ...rest }) => {
  const iconCollection = sparkyIcons as Partial<{
    [key: string]: FC<IconProps>;
  }>;

  const Icon = iconCollection[iconName];

  useEffect(() => {
    if (!Icon && fallbackIcon === undefined) {
      logger.error('wVRxzv', `Icon ${iconName} could not be found. A placeholder is rendered.`);
    }
  }, [Icon, fallbackIcon, iconName]);

  if (Icon) return <Icon {...rest} />;

  if (fallbackIcon) return fallbackIcon;

  return <CloseIcon {...rest} />;
};
